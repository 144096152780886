import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RootApp from './route/root/RootApp';
import reportWebVitals from './reportWebVitals';
// import PureCssApp from "./PureCssApp";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import AboutApp from "./route/about/AboutApp";
import PortfolioPage from "./route/portfolio/PortfolioPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootApp/>,
    },
    {
        path: "about",
        element: <AboutApp/>
    },
    {
        path: "portfolio",
        element: <PortfolioPage/>
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
        {/*<PureCssApp/>*/}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
