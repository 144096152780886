import './DefaultPage.scss';
import React, {useEffect} from "react";
import {
    Link
} from "react-router-dom"

function AppendLink(content: string, link: string) {
    return <a className="link" href={link} target="_blank">{content}</a>
}

export function Section(props: { className: string, bodyClassName: string, id: string, titleIcon: JSX.Element, title: string | JSX.Element, children: React.ReactNode }) {
    return <div id={props.id} className={`${props.className !== "" ? props.className + " " : ""}section`}>
        <p className="title">{props.titleIcon}{props.titleIcon === null ? props.title : ` ${props.title}`}</p>
        <div className={`${props.bodyClassName !== "" ? props.bodyClassName + " " : ""}body`}>
            {props.children}
        </div>
    </div>;
}

Section.defaultProps = {
    className: "",
    bodyClassName: "",
    titleIcon: null,
}

// export function BodySection(props: { className: string, bodyClassName: string, id: string, titleIcon: JSX.Element, title: string, children: React.ReactNode }) {
//     return <div id={props.id} className={`${props.className !== "" ? props.className + " " : ""}section`}>
//         <p className="title">{props.titleIcon}{props.titleIcon === null ? props.title : ` ${props.title}`}</p>
//         <div className={`${props.bodyClassName !== "" ? props.bodyClassName + " " : ""}body`}>
//             {props.children}
//         </div>
//     </div>;
// }
//
// BodySection.defaultProps = {
//     id: "",
//     className: "",
//     bodyClassName: "",
//     titleIcon: null
// }

const DefaultPage = (props: {
    children: React.ReactNode
}) => {

    // fixes the issue where background movement spikes on first scroll movement
    useEffect(() => {
        let element = document.getElementsByClassName("background").item(0)! as HTMLDivElement
        element.style.backgroundPositionY = "0%"
    }, [])

    return <>
        <div id="main" className="pure-g">
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/purecss@3.0.0/build/grids-responsive-min.css"/>
            <div className="background"></div>

            <div id="header" className={"pure-u-1"}>
                <div className="item pure-u-1-2">
                    <Link to="/">Home</Link>
                </div>
                <div className="items pure-u-1-2">
                    <Link className="item pure-u-3-5 pure-u-md-1-5" to="/about">About</Link>
                    <Link className="item box pure-u-3-5 pure-u-md-1-5" to="/portfolio">Portfolio</Link>
                </div>
            </div>

            <div id="body" className="pure-u-1">
                {props.children}
            </div>
        </div>

        <div id="footer" className="pure-u-1">
            <div className="body">
                <div className="links section">
                    <div className="title">Links</div>
                    <div className="body">
                        <p>{AppendLink("GitHub", "https://github.com/Tofpu/")}</p>
                        <p>{AppendLink("Spigot", "https://www.spigotmc.org/members/.585786/")}</p>
                    </div>
                </div>
            </div>
            <div className="credits">
                <p className="box">Created with ❤ by Tofpu</p>
            </div>
        </div>
    </>
}

export default DefaultPage;