import DefaultPage, {Section} from "../DefaultPage";
import {Font500, Text} from "../../HtmlUtil";
import "./AboutApp.scss"

function AboutApp() {
    return <DefaultPage>
        <Section id="about" title={Text("About ", Font500("Tofpu"))} className="pure-u-1 pure-u-lg-1-2">
            <p>I am a self-taught software developer with over four years of experience in turning ideas into reality with the use of software.</p>
            <p><br/>
                In my early days as an amateur software developer, I created a discord server dedicated for creating Minecraft plugins based on the requests of our members. This served as a means of both tunneling my inner passion of creating computer programs, and to broaden my horizons.</p>
            <p><br/>As a result of this, I published a portion of my work to the wide internet as people found it valuable. The honorable mention are as follows:</p>
            <ul>
                <li>Speedbridge (on Sep 2021; 1st iteration)</li>
                <li>Speedbridge2 (Aug 2023; 2nd iteration)</li>
            </ul>

            <p><br/>At the beginning of 2023, I begun working on client projects which are, needless to say, private, and thus not presented on my public profile.</p>

            <p><br/>These projects helped drive my continuous ambition of discovering, and experimenting with various technologies, patterns and designs to better cope with a growing codebase.</p>
        </Section>
    </DefaultPage>
}

export default AboutApp;