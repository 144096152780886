import React from "react";
import ReactDOM from "react-dom/client";
import {renderToStaticMarkup} from "react-dom/server";

function Font700(content: string) {
    return <span className="font-700">{content}</span>
}

function Font600(content: string) {
    return <span className="font-600">{content}</span>
}

function Font500(content: string) {
    return <span className="font-500">{content}</span>
}

function HTMLToString(element: React.JSX.Element): string {
    return renderToStaticMarkup(element)
}

function Font400(content: string) {
    return <span className="font-400">{content}</span>
}

function Underline(content: string) {
    return <span className="underline">{content}</span>
}

function Text(content: string, replace: React.JSX.Element) {
    return <p>{content}{replace}</p>
}

function Apply(content: string, ...effects: ((content: string) => React.JSX.Element)[]) {
    const classes = effects.map(effect => {
        return effect(content).props.className
    }).join(" ");
    return <span className={classes}>{content}</span>
}

export {
    Font700,
    Font600,
    Font500,
    Font400,
    Underline,
    Apply,
    HTMLToString,
    Text
}