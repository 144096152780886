import React, {ReactNode, useEffect} from 'react';
import './RootApp.css';

import publicResourceIcon from '../../assets/ant-design_project-outlined.svg'

import downloadsIcon from '../../assets/ion_download.svg'
import reviewsIcon from '../../assets/Reviews.svg'
import starsIcon from '../../assets/Stars.svg'

import speedbridgeIcon from '../../assets/speedbridge2.png'
import leaderboardIcon from '../../assets/material-symbols_leaderboard-outline.svg'
import statisticsIcon from '../../assets/statistics.svg'
import gameScaleIcon from '../../assets/fluent-mdl2_scale-volume.svg'

import starReviewIcon from '../../assets/star.svg'
import DefaultPage, {Section} from "../DefaultPage";
import {Font400, Font500, Font600, Font700, Underline} from "../../HtmlUtil";
// import expertiseIcon from './assets/carbon_ibm-watson-knowledge-catalog.svg'
// import applicationIcon from './assets/carbon_load-balancer-application.svg'
// import otherProjectsIcon from './assets/pajamas_project.svg'
// import languageAndToolsIcon from './assets/la_tools.svg'

require("purecss")

function Icon(url: string) {
    return <img className="icon" src={url} alt="not implemented, sorry"/>
}

function Feature(url: string, title: string, description: string) {
    return <div className="pure-u-1 pure-u-md-1-3 pure-u-xl-1-4 pure-u-xxl-1-4 pure-u-xxxl-1-5">
        <div className="feature">
            <div className="icon">
                {Icon(url)}
            </div>
            <p className="title">{title}</p>
            <p className="description">{description}</p>
        </div>
    </div>
}

function Testimony(url: string, description: string) {
    return <div className="pure-u-1 pure-u-md-1-3">
        <div className="testimony">
            <div className="stars">
                {Icon(url)}
                {Icon(url)}
                {Icon(url)}
                {Icon(url)}
                {Icon(url)}
            </div>
            <p className="description">"{description}"</p>
            <p className="from">Consumer</p>
        </div>
    </div>
}

function ProductItemSection(props: { title: string, className: string, children: ReactNode }) {
    return <div className={`item ${props.className}`}>
        <p className="title">{props.title}</p>
        <div className="body pure-g">
            {props.children}
        </div>
    </div>
}

function moveBackground() {
    let element = document.getElementsByClassName("background").item(0)! as HTMLDivElement
    let scrollPercent = getScrollPercent();

    console.log(`backgroundPositionY=${element.style.backgroundPositionY}`)
    console.log(`scrollPercent=${scrollPercent}`)

    element.style.backgroundPositionY = `${scrollPercent * 0.5}%`
}

window.addEventListener('scroll', moveBackground)
window.addEventListener('touchmove', moveBackground)

function height() {
    const h = document.documentElement,
        b = document.body,
        sh = 'scrollHeight';
    // @ts-ignore
    return (h[sh] || b[sh]) - h.clientHeight;
}

function getScrollPercent() {
    const h = document.documentElement,
        b = document.body,
        st = 'scrollTop'
    // @ts-ignore
    return (h[st]||b[st]) / height() * 100;
}

function Link(content: string, link: string) {
    return <a className="link" href={link} target="_blank">{content}</a>
}

function redirectTo(destination: string) {
    window.location.href = destination
}

function RootApp() {
    return <DefaultPage>
        <div id="heading" className="pure-u-1 pure-u-md-1-2 pure-u-lg-18-24 pure-u-xl-11-24 pure-u-xxl-9-24 pure-u-xxxl-7-24">
            <div className="pure-u-1">
                <p className="header pure-u-1">{Font700("Software Developer")} with
                    4YOE at {Font700("Your Service")}</p>
            </div>
            <p className="subtitle">Hi! I go by the {Font600("Tofpu")} persona online.</p>
            <p className="subtitle header"><br/>I {Font500("strive")} to
                create {Font500("quality software products")} that
                is {Underline("efficient")}, {Underline("reliable")}, and {Underline("maintainable")}.</p>
        </div>

        <Section id="statistics" titleIcon={Icon(statisticsIcon)} title="Spigot Resources` Statistics">
            <p className="item">{Icon(downloadsIcon)} {Font400("9.5k")} &nbsp;total downloads</p>
            <p className="item">{Icon(reviewsIcon)} {Font400("48")} &nbsp;total reviews</p>
            <p className="item">{Icon(starsIcon)} {Font400("4.98")} &nbsp;average stars</p>
        </Section>

        <Section id="public-resources" titleIcon={Icon(publicResourceIcon)} title="Public resources">
            <div id="speedbridge" className="product">
                <p className="title">{Icon(speedbridgeIcon)} Speedbridge2</p>
                <p className="description">A Minecraft gamemode where players must hop into an island, bridge
                    from one island to another, and try to get the lowest score possible.</p>

                <ProductItemSection title="Features" className="features">
                    {Feature(leaderboardIcon, "Leaderboard System", "Aggregates all kinds of player data to present top performers in their respective class.")}
                    {Feature(statisticsIcon, "Player Statistics", "Collects players' activities to allow self evaluation of performance.")}
                    {Feature(gameScaleIcon, "Game Scaling", "Gathers game-related information setup to scale games per demand.")}
                </ProductItemSection>

                <ProductItemSection title="Testimonials" className="testimonials">
                    {Testimony(starReviewIcon, "This was probably one of the best plugins ever invented. These amazing people made this for FREE and give out amazing support!")}
                    {Testimony(starReviewIcon, "The author is very helpful and the best support I've ever seen, I had an issue with the plugin not working correctly, turns out it was related to my Startup flags, and not even a week later he fixed the issue in his plugin. Would recommend this plugin 11/10")}
                    {Testimony(starReviewIcon, "Amazing update! I can't believe its still going! I've added replays because of how compatible it is!")}
                    {Testimony(starReviewIcon, "Better than v1 and also open source, so i can mod it to my liking. BEST FASTBUILDER PLUGIN EVER")}
                </ProductItemSection>
            </div>
        </Section>

        {/*<BodySection id="knowledge" titleIcon={Icon(expertiseIcon)} title="Expertise" bodyClassName="pure-g">*/}
        {/*    <BodySection id="expertise" titleIcon={Icon(applicationIcon)} title="Applications" className="pure-u-1-3 pure-u-md-1-3">*/}
        {/*        <ul>*/}
        {/*            <li>Minigame</li>*/}
        {/*            <li>Libraries</li>*/}
        {/*            <li>Tools / Utilities</li>*/}
        {/*        </ul>*/}
        {/*    </BodySection>*/}

        {/*    <BodySection id="other-projects" titleIcon={Icon(otherProjectsIcon)} title="Other projects" className="pure-u-1 pure-u-md-1-3">*/}
        {/*        <ul>*/}
        {/*            <li>Voucher</li>*/}
        {/*            <li>Inventory Synchronization (between servers)</li>*/}
        {/*            <li>Ranks</li>*/}
        {/*        </ul>*/}
        {/*    </BodySection>*/}

        {/*    <BodySection id="languages-and-tools" titleIcon={Icon(languageAndToolsIcon)} title="Languages and Tools" className="pure-u-1">*/}
        {/*        <ul>*/}
        {/*            <li>Java & TS/JS</li>*/}
        {/*            <li>React, Node & WebSocket</li>*/}
        {/*            <li>MySQL, SQLite & H2</li>*/}
        {/*            <li>Maven & Gradle Groovy/DSL</li>*/}
        {/*            <li>Hibernate OEM</li>*/}
        {/*            <li>Git</li>*/}
        {/*            <li>Bukkit/Spigot/Paper</li>*/}
        {/*            <li>Caffeine (Caching Library)</li>*/}
        {/*            <li>RESTful API</li>*/}
        {/*            <li>Log4j2</li>*/}
        {/*        </ul>*/}
        {/*    </BodySection>*/}
        {/*</BodySection>*/}

        <Section id="services" title="Services">
            <p>I charge a clear and definitive rate of {Font400("$15/h")} in exchange for my extensive expertise on Software Development and Minecraft in general.</p>
            <p><br/>Chat with me on discord at my {Underline("@tofpu")} handle. Looking forward in working together!</p>
        </Section>
    </DefaultPage>

    // return (
    //     <div id="main" className="pure-g">
    //         <meta name="viewport" content="width=device-width, initial-scale=1"/>
    //         <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/purecss@3.0.0/build/grids-responsive-min.css"/>
    //         <div className="background"></div>
    //
    //         <div id="footer" className="pure-u-1">
    //             <div className="item pure-u-1-2">
    //                 <button onClick={() => redirectTo("/")}>Home</button>
    //             </div>
    //             <div className="items pure-u-1-2">
    //                 <button className="item pure-u-3-5 pure-u-md-1-5" onClick={() => redirectTo("/about")}>About</button>
    //                 <button className="item box pure-u-3-5 pure-u-md-1-5" onClick={() => redirectTo("/portfolio")}>Portfolio</button>
    //             </div>
    //         </div>
    //
    //         <div id="body" className="pure-u-1">
    //             <div id="heading" className="pure-u-1 pure-u-md-1-2 pure-u-lg-18-24 pure-u-xl-11-24 pure-u-xxl-9-24 pure-u-xxxl-7-24">
    //                 <div className="pure-u-1">
    //                     <p className="header pure-u-1">{Font700("Software Developer")} with
    //                         4YOE at {Font700("Your Service")}</p>
    //                 </div>
    //                 <p className="subtitle">Hi! I go by the {Font600("Tofpu")} persona online.</p>
    //                 <p className="subtitle header"><br/>I {Font500("strive")} to
    //                     create {Font500("quality software products")} that
    //                     is {Underline("efficient")}, {Underline("reliable")}, and {Underline("maintainable")}.</p>
    //             </div>
    //
    //             <RootSection id="statistics" titleIcon={Icon(statisticsIcon)} title="Spigot Resources` Statistics">
    //                 <div className="content">
    //                     <p className="item">{Icon(downloadsIcon)} 9.5k total downloads</p>
    //                     <p className="item">{Icon(reviewsIcon)} 48 total reviews</p>
    //                     <p className="item">{Icon(starsIcon)} 4.98 average stars</p>
    //                 </div>
    //             </RootSection>
    //
    //             <RootSection id="public-resources" titleIcon={Icon(publicResourceIcon)} title="Public resources">
    //                 <div id="speedbridge" className="section product">
    //                     <p className="title">{Icon(speedbridgeIcon)} Speedbridge2</p>
    //                     <p className="description">A Minecraft gamemode where players must hop into an island, bridge
    //                         from one island to another, and try to get the lowest score possible.</p>
    //
    //                     <ProductItemSection title="Features" className="features">
    //                         {Feature(leaderboardIcon, "Leaderboard System", "Aggregates all kinds of player data to present top performers in their respective class.")}
    //                         {Feature(statisticsIcon, "Player Statistics", "Collects players' activities to allow self evaluation of performance.")}
    //                         {Feature(gameScaleIcon, "Game Scaling", "Gathers game-related information setup to scale games per demand.")}
    //                     </ProductItemSection>
    //
    //                     <ProductItemSection title="Testimonials" className="testimonials">
    //                         {Testimony(starReviewIcon, "This was probably one of the best plugins ever invented. These amazing people made this for FREE and give out amazing support!")}
    //                         {Testimony(starReviewIcon, "The author is very helpful and the best support I've ever seen, I had an issue with the plugin not working correctly, turns out it was related to my Startup flags, and not even a week later he fixed the issue in his plugin. Would recommend this plugin 11/10")}
    //                         {Testimony(starReviewIcon, "Amazing update! I can't believe its still going! I've added replays because of how compatible it is!")}
    //                         {Testimony(starReviewIcon, "Better than v1 and also open source, so i can mod it to my liking. BEST FASTBUILDER PLUGIN EVER")}
    //                     </ProductItemSection>
    //                 </div>
    //             </RootSection>
    //
    //             {/*<BodySection id="knowledge" titleIcon={Icon(expertiseIcon)} title="Expertise" bodyClassName="pure-g">*/}
    //             {/*    <BodySection id="expertise" titleIcon={Icon(applicationIcon)} title="Applications" className="pure-u-1-3 pure-u-md-1-3">*/}
    //             {/*        <ul>*/}
    //             {/*            <li>Minigame</li>*/}
    //             {/*            <li>Libraries</li>*/}
    //             {/*            <li>Tools / Utilities</li>*/}
    //             {/*        </ul>*/}
    //             {/*    </BodySection>*/}
    //
    //             {/*    <BodySection id="other-projects" titleIcon={Icon(otherProjectsIcon)} title="Other projects" className="pure-u-1 pure-u-md-1-3">*/}
    //             {/*        <ul>*/}
    //             {/*            <li>Voucher</li>*/}
    //             {/*            <li>Inventory Synchronization (between servers)</li>*/}
    //             {/*            <li>Ranks</li>*/}
    //             {/*        </ul>*/}
    //             {/*    </BodySection>*/}
    //
    //             {/*    <BodySection id="languages-and-tools" titleIcon={Icon(languageAndToolsIcon)} title="Languages and Tools" className="pure-u-1">*/}
    //             {/*        <ul>*/}
    //             {/*            <li>Java & TS/JS</li>*/}
    //             {/*            <li>React, Node & WebSocket</li>*/}
    //             {/*            <li>MySQL, SQLite & H2</li>*/}
    //             {/*            <li>Maven & Gradle Groovy/DSL</li>*/}
    //             {/*            <li>Hibernate OEM</li>*/}
    //             {/*            <li>Git</li>*/}
    //             {/*            <li>Bukkit/Spigot/Paper</li>*/}
    //             {/*            <li>Caffeine (Caching Library)</li>*/}
    //             {/*            <li>RESTful API</li>*/}
    //             {/*            <li>Log4j2</li>*/}
    //             {/*        </ul>*/}
    //             {/*    </BodySection>*/}
    //             {/*</BodySection>*/}
    //
    //             <RootSection id="services" title="Services">
    //                 <div className="body">
    //                     <p>I charge a clear and definitive rate of {Font400("$15/h")} in exchange for my extensive expertise on Software Development and Minecraft in general.</p>
    //                     <p><br/>Chat with me on discord at my {Underline("@tofpu")} handle. Looking forward in working together!</p>
    //                 </div>
    //             </RootSection>
    //         </div>
    //         <div id="header" className="pure-u-1">
    //             <div className="body">
    //                 <div className="links section">
    //                     <div className="title">Links</div>
    //                     <div className="body">
    //                         <p>{Link("GitHub", "https://github.com/Tofpu/")}</p>
    //                         <p>{Link("Spigot", "https://www.spigotmc.org/members/.585786/")}</p>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="credits">
    //                 <p className="box">Created with ❤ by Tofpu</p>
    //             </div>
    //         </div>
    //     </div>
    // );
}

export default RootApp;